.rct-node-icon{
    padding: 0px !important;
}
.react-checkbox-tree ol ol{
    padding-left: 0px;
}
.h6{
    font-weight: 100;
}
.tox-tinymce {
border: none !important;
border-radius: 0px !important;
}
.apexcharts-legend{
top: 15%!important;
right: 5%!important;
}
.carousel-control-next-icon{
    background-color:#727272;
    border-radius: 50%;
    zoom: 1.5;
    
}
.carousel-control-prev-icon{
    background-color:#727272;
    border-radius: 50%;
    zoom: 1.5;
    
}
.new-nav-pills {
    
    margin-top: 5rem;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    flex-direction: row !important;
    width: 100%;
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    margin-bottom: -500px !important;
} 
.nav-pills .nav-link.active{
    background-color: white;
    color: black;
}

/* .form-check-input:checked[type=checkbox] {
    background-image: url('./../../../public/media/X-btn.svg');
} */
/* .nav-pills .nav-link.active{
    border-radius: 0px;
    background-color: transparent !important;
    border-bottom-width:2px !important;
    border-bottom: 1px solid;
    padding: 0.5rem !important;
    margin: 0 1rem !important;
    border-color: #009EF7 !important;
    color:#009EF7 ;
}  */

.symbol-symbol-label:hover{
    transform: scale(1.6);
    z-index: 2;
    
    
}
.symbol-symbol-label{
    justify-content: center;
    display: flex;
    flex-direction: row;
    
}


