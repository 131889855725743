
input[type=range]::-webkit-slider-runnable-track {
    height: 10px;
    background: #ddd;
    border: none;
    border-radius: 8px;
}
input[type=range]::-webkit-slider-thumb {
    
    border: none;
    height: 50px !important;
    width: 50px !important;
    margin-top: -2px;
}
.open{
    accent-color: #50CD89;

}
.block{
    accent-color: #6E6F6F;


}
.delete{
    accent-color: #FF000A;


}
.erase{
    accent-color: #8950FC;
}