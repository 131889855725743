.tox:not(.tox-tinymce-inline) .tox-editor-header {
    background-color: #181c32 !important
}

.tox .tox-tbtn svg{
    fill:white !important
}
.tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary{
    background-color: #181c32 !important
}
.tox-editor-header{
    background-color: #181c32 !important

}

#tinymce.mce-content-body{
    font-family: Atma !important;
}
.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: white; 
  }

  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
    color: white;
  }

  .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: #FFFFFF; }
