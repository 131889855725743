.cls-1, .cls-2, .cls-3, .cls-6 {
    fill: none;
  }

  .cls-1, .cls-3 {
    stroke: #181c32;
    stroke-width: 3px;
  }

  .cls-1 {
    stroke-linecap: round;
  }

  .cls-4, .cls-5 {
    stroke: none;
  }

  .cls-5 {
    fill: #181c32;
  }
  #inputID::placeholder {
    color: #ffffff;
}
